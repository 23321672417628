import React from 'react';
import Modal from 'react-modal';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const ModalItem = ({ children, isOpen, onRequestClose }) => {
    return (
        <div
        >
            <Modal
                isOpen={isOpen}
                onRequestClose={onRequestClose}
                style={customStyles}
                contentLabel="Example Modal"
            >
                {children}
            </Modal>
        </div>
    );
}

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#___gatsby');

export default ModalItem;
